import { inject, Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected toastCtrl: ToastController
  constructor() {
    this.toastCtrl = inject(ToastController)
  }

  async showToast(message = '') {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000
    });

    return toast.present();
  }
}

