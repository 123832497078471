import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { ReviewAddComponent } from './review-add/review-add.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  declarations: [HeaderComponent, ReviewAddComponent],
  exports: [HeaderComponent, ReviewAddComponent]
})
export class ComponentsModule { }
