import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { ReviewService } from 'src/app/services/review.service';

@Component({
  selector: 'app-review-add',
  templateUrl: './review-add.component.html',
  styleUrls: ['./review-add.component.scss'],
})
export class ReviewAddComponent implements OnInit {

  constructor(private modalController: ModalController, private fb: FormBuilder, private reviewService: ReviewService, private toastController: ToastController) { }
  form: FormGroup
  isSaving: boolean;
  ngOnInit() {
    this.form = this.fb.group({
      name: [],
      description: []
    });
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    try {
      this.isSaving = true;
      const formValues = this.form.getRawValue();
      const payload = { data: formValues }
      const review = await this.reviewService.create(payload)
      console.log(review)

      this.onDismiss()
    } catch (err) {
      this.isSaving = false;
      const toast = await this.toastController.create({ message: "Failed to save review" })
      toast.present();
    }

  }
}
