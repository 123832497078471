// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:1337',
  baseUrl: 'https://doghouse-backend.herokuapp.com',
  serverEndpoint: '/api',
  token: 'b1d30455cb53b27773567e1b54be9700e315dea5f4afdf6e6da1eafc7a2e2526cc3178affae62b8c6ca23363aa96c3642e1bcfabdf1ffd598b81400633b0a408477f411fab4df84d3bbe7804db05c8486b2bbc5742516f21b0e748813e798706c29b06d72108935a2c42a6604f2115c9b1c504728f55ce90d72aae3a55713ede'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
