import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends BaseService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpService) {
    super();
  }

  async loadAllReviews() {
    try {
      let res = await this.http.get('reviews?populate=*');
      console.log(res)
      res = res.data.map(review => {
        review.photo = `${this.baseUrl}/${review?.attributes?.photo?.data?.attributes?.url}`
        return review;
      });
      console.log(res)
      return res;
    } catch (err) {
      this.showToast(err.message)
    }
  }

  create(review) {
    try {
      const data = this.http.post('reviews', review)
      return data;
    } catch (err) {
      this.showToast(err.message)
    }
  }
}
