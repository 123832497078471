import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  navLinks = [{ url: '/about', label: 'About' },
  { url: '/reviews', label: 'Reviews' },
  { url: '/services', label: 'Services' },
  { url: '/sign-up', label: 'Sign Up' },]
  currentUrl = ''
  constructor(private router: Router) {
    this.subscribeToRouterChanges();
  }

  ngOnInit() { }

  subscribeToRouterChanges() {
    this.router.events.subscribe(event => {
      this.currentUrl = this.router.url;
    });
  }

}
