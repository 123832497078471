import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'About', url: '/about', icon: 'information-circle' },
    { title: 'Services', url: '/services', icon: 'briefcase' },
    { title: 'Reviews', url: '/reviews', icon: 'star' },
    { title: 'SignUp', url: '/signup', icon: 'person' },
  ];
  constructor() { }
}
