import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.baseUrl + environment.serverEndpoint;
  protected httpClient: HttpClient;
  constructor() {
    this.httpClient = inject(HttpClient);
  }
  get(url, options?) {
    return this.request('GET', url, options);
  }

  post(url, body, options?) {
    return this.request('POST', url, body, options);
  }

  patch(url, body, options?) {
    return this.request('PATCH', url, body, options);
  }

  delete(url, body?) {
    return this.request('DELETE', url, body);
  }

  async request(method, reqUrl, body?, options?) {
    const url = `${this.baseUrl}/${reqUrl}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const reqOpt = { headers, body };
    if (body) {
      reqOpt.body = body;
    }
    console.log(url, method, reqOpt)

    try {
      const response: any = await this.httpClient.request(
        method,
        url,
        reqOpt
      ).toPromise();
      return response;
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
}
