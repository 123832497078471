import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends BaseService {
  constructor(private http: HttpService) {
    super();
  }

  async loadAllServices() {
    try {
      const services = await this.http.get('services');
      console.log(services)
      const { data } = services;
      return data;
    } catch (error) {
      this.showToast(error.message)
      throw error;
    }
  }
}
